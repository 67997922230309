import React, { useState } from 'react';
import { names as _names } from './data'

const name = () => _names[Math.floor(Math.random() * _names.length)]

const Home = () => {
  const [dogs] = useState(
    new Array(99)
      .fill(null)
      .map((dog, i) => ({id: i + 1}))
  )

  return <div style={{
    paddingTop: '20px',
    textAlign: 'center',
    columnWidth: '250px',
    columnGap: 0,
  }}>
    {dogs.map(({id}) =>
      <div key={id} style={{
        marginBottom: '20px',
        breakInside: 'avoid'
      }}>
        <img style={{
          // borderStyle: 'ridge',
          // borderWidth: '1px',
          // borderColor: 'lightgrey',
          // boxShadow: '6px 0px 0 0 lightgrey',
          borderRadius: '10% 10% 0px 0px',
          breakInside: 'avoid',
          padding: '0 10px 0 10px',
          width: '90%'
        }} alt="" src={`cats/cat_${id}.jpg`} />
        <br />
        <h2 style={{
          margin: '0 10px 10px 10px',
          textAlign: 'center',
          borderWidth: '0 1px 1px 1px',
          borderStyle: 'solid',
          borderColor: 'lightgrey',
          borderRadius: '0 0 15px 15px',
        }}>{name()}</h2>
      </div>
    )}
  </div>
};

export default Home;
