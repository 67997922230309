export const names = [
  'Oscar',
  'Max',
  'Tiger',
  'Sam',
  'Misty',
  'Simba',
  'Coco',
  'Chloe',
  'Lucy',
  'Missy',
  'Molly',
  'Tigger',
  'Smokey',
  'Milo',
  'Cleo',
  'Sooty',
  'Monty',
  'Puss',
  'Kitty',
  'Felix',
  'Bella',
  'Jack',
  'Lucky',
  'Casper',
  'Charlie',
  'Thomas',
  'Toby',
  'Ginger',
  'Oliver',
  'Daisy',
  'Gizmo',
  'Muffin',
  'Jessie',
  'Sophie',
  'Fluffy',
  'Sebastian',
  'Billy',
  'Jasper',
  'Jasmine',
  'Sasha',
  'Zoe',
  'Phoebe',
  'Tom',
  'Lilly',
  'Sylvester',
  'George',
  'Kimba',
  'Harry',
  'Holly',
  'Minnie'
]
